import { ImageCover } from '/features/buildingBlocks/Image'
import { Link } from '/features/buildingBlocks/Link'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import { ScrollAnimation } from '/features/buildingBlocks/ScrollAnimation'

import linkedinIcon from '/images/icons/share/linkedin.raw.svg'
import emailIcon from '/images/icons/contact.raw.svg'

import styles from './Recruiter.css'

export function Recruiter({ image, name, email, linkedin }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <ScrollAnimation
        layoutClassName={styles.lineRecruiter}
        width={644}
        height={510}
        path="M-70.3403 509.625L232.84 0.375H643.34L339.942 509.625H-70.3403Z"
      />
      <div className={styles.coverImage}>
        {image?.asset && (
          <ImageCover
            {...{ image }}
            layoutClassName={styles.image}
            aspectRatio={7 / 9}
          />
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <span className={styles.titleFirst}>{__`recruiter-heading-label-i`}</span>
          <span className={styles.titleSecond}>{__`recruiter-heading-label-ii`}</span>
        </div>
        <p className={styles.description}>{__`recruiter-description`}</p>
        <div className={styles.information}>
          <span className={styles.name}>{name}</span>
          <Link
            href={`mailto:${email}`}
            dataX='link-to-email'
            layoutClassName={styles.link}
          >
            <Icon icon={emailIcon} layoutClassName={styles.icon} />
            {email}
          </Link>
          {linkedin && (
            <Link
              href={linkedin}
              dataX='link-to-linkedin'
              layoutClassName={styles.link}
            >
              <Icon icon={linkedinIcon} layoutClassName={styles.icon} />
              {__`linkedin-label`}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
