import styles from './Link.css'

export function LinkBlue({ href, dataX, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentBlue, layoutClassName)}
      {...{ href, dataX, target }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkUnderline({ href, dataX, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentUnderline, layoutClassName)}
      {...{ href, dataX, target }}
    >
      {children}
    </LinkBase>
  )
}

export function Link({ href, dataX, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase
      className={cx(styles.component, layoutClassName)}
      {...{ href, dataX, target }}
    >
      {children}
    </LinkBase>
  )
}

function LinkBase({ href, dataX, children, target = undefined, className = undefined }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : null

  return (
    <a
      data-x={dataX}
      className={cx(styles.componentBase, className)}
      {...{ href, rel, target }}
    >
      {children}
    </a>
  )
}
